import './scss/index.scss';

import scrollMonitor from 'scrollmonitor';
import imagesLoaded from 'imagesloaded';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';
import VanillaScrollspy from 'vanillajs-scrollspy';

//#region parallax
let parallax = document.querySelector('.js-parallax');

if (parallax) {
  window.addEventListener('scroll', function() {
    let offset = window.pageYOffset;
    parallax.style.transform = `translate(0, -${offset * 0.2}px)`;
  });
}
//#endregion

//#region sticky navbar
let stickyNavbar = document.querySelector('.navbar');
let navbarOriginalHeight = `${stickyNavbar.clientHeight}px`;
let body = document.querySelector('body');
let stripWatcher = scrollMonitor.create(stickyNavbar);
stripWatcher.lock();
stripWatcher.fullyEnterViewport(function() {
  stickyNavbar.classList.remove('navbar--sticky');
  body.style.paddingTop = '0px';
});
stripWatcher.partiallyExitViewport(function() {
  stickyNavbar.classList.add('navbar--sticky');
  console.log(stickyNavbar.clientHeight);
  body.style.paddingTop = navbarOriginalHeight;
});
//#endregion

//#region navbar toggle
let navbarToggle = document.querySelector('.navbar__toggle');
let navbarNav = document.querySelector('.navbar__nav');
let navbarLink = document.querySelectorAll('.navbar__link');
navbarToggle.addEventListener('click', function(e) {
  e.preventDefault();
  navbarNav.classList.add('open');
  disableBodyScroll(navbarNav);
});
let navbarClose = document.querySelector('.navbar__close');
navbarClose.addEventListener('click', function(e) {
  e.preventDefault();
  navbarNav.classList.remove('open');
  enableBodyScroll(navbarNav);
});
navbarLink.forEach(link => {
  link.addEventListener('click', function(e) {
    navbarNav.classList.remove('open');
    enableBodyScroll(navbarNav);
  });
});
//#endregion

//#region scrollspy
const navbar = document.querySelector('#navbar');
const scrollspy = new VanillaScrollspy(navbar, 1000, 'easeInOutQuint');
scrollspy.init();
//#endregion
